import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Popper from "popper.js";
window.Popper = Popper;

import Cookies from "js-cookie";

//
// Bootstrap components -- Unneeded components are commented-out.
//

import "bootstrap/js/dist/alert.js";
import "bootstrap/js/dist/button.js";
// import "bootstrap/js/dist/carousel.js";
import "bootstrap/js/dist/collapse.js";
import "bootstrap/js/dist/dropdown.js";
import "bootstrap/js/dist/modal.js";
// import "bootstrap/js/dist/popover.js";
// import "bootstrap/js/dist/scrollspy.js";
import "bootstrap/js/dist/tab.js";
// import "bootstrap/js/dist/toast.js";
// import "bootstrap/js/dist/tooltip.js";
import "bootstrap/js/dist/util.js";

//
// CKOL French translation message.
//

jQuery(function ($) {
  if (!Cookies.get("fr-message-done")) {
    $(".js-fr-message").removeClass("d-none");
  }
  $(".js-fr-message .js-close").on("click", function (_e) {
    Cookies.set("fr-message-done", true);
    $(".js-fr-message").addClass("d-none");
  });
});
